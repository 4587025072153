.App {
  display: flex;
  /* align-items: ; */
  /* justify-content: space-around; */
}

.search-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  font-size: 20px;
  margin: 10px auto;
  padding: 10px 20px;
}

.head-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.react-tabs__tab {
  font-size: 13px;
}

.container {
  width: 50% !important;
}
